import { render, staticRenderFns } from "./StackChart.vue?vue&type=template&id=3eb943d8&scoped=true&"
import script from "./StackChart.vue?vue&type=script&lang=js&"
export * from "./StackChart.vue?vue&type=script&lang=js&"
import style0 from "./StackChart.vue?vue&type=style&index=0&id=3eb943d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eb943d8",
  null
  
)

export default component.exports